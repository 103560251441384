import React from 'react';
import '../../sass/timer.css';

const Timer = ({creationDate, customStyle}) => {

    const formatMinutes = (value) => {
        if (value === 1) {
            return '1 minutę temu';
        } else if ((value > 1 && value < 5) || ((value % 10 > 1 && value % 10 < 5) && (value % 100 < 12 || value % 100 > 14))) {
            return value + ' minuty temu';
        } else {
            return value + ' minut temu';
        }
    };

    const formatTime = (value, type) => {
        switch (type) {
            case 'minute':
                return formatMinutes(value);
            case 'hour':
                return value === 1 ? '1 godz. temu' : value + ' godz. temu';
            case 'day':
                return value === 1 ? '1 dzień temu' : value + ' dni temu';
            default:
                return '';
        }
    };

    const formatDate = (date) => {
        const options = {day: 'numeric', month: 'long', year: 'numeric'};
        return new Intl.DateTimeFormat(navigator.language, options).format(new Date(date));
    };

    const convertToDateAgo = (dateString) => {
        const publicationTime = new Date(dateString).getTime();
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - publicationTime;

        const minute = 60 * 1000;
        const hour = minute * 60;
        const day = hour * 24;
        const week = day * 7;

        if (timeDifference < minute) {
            return 'Przed chwilą';
        } else if (timeDifference < hour) {
            return formatTime(Math.round(timeDifference / minute), 'minute');
        } else if (timeDifference < day) {
            return formatTime(Math.round(timeDifference / hour), 'hour');
        } else if (timeDifference < week) {
            return formatTime(Math.round(timeDifference / day), 'day');
        } else {
            return formatDate(dateString);
        }
    };

    return (
        <div className="timerWrapper"
             style={customStyle === 'withMargin' ? {marginLeft: '3.5rem'} : {}}
        >
            <span className="timerText">{convertToDateAgo(creationDate)}</span>
        </div>
    );
};

export default Timer;
