import React from "react";
import {ReactComponent as CategoryIcon} from '../../img/icons/categoryIcon.svg';
import '../../sass/category.css';

const Category = ({category}) => {

    const categoryMappings = {
        NEWS: {
            text: "Wydarzenia",
            color: "#D90042"
        },
        SPORT: {
            text: "Sport",
            color: "#10bbdf"
        },
        POLITICS: {
            text: "Polityka",
            color: "#FF9C00"
        },
        BUSINESSANDFINANCE: {
            text: "Biznes i finanse",
            color: "#5120ff"
        }

    }
    const categoryInfo = categoryMappings[category] || {text: "NEWS", color: "#1ED88A"};

    return (
        <div className="categoryWrapper">
            <CategoryIcon className="categoryIcon" style={{fill: categoryInfo.color}}/>
            <h3 className="categoryText">{categoryInfo.text}</h3>
        </div>
    );
};

export default Category;
