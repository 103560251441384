import React, {useEffect, useRef, memo} from 'react';
// import {ThemeContext} from "../components/context/ThemeContext";

function LineChartWidget({symbols}) {
    const container = useRef();

    // const {theme} = useContext(ThemeContext);
    // const colorTheme = theme === 'dark' ? 'dark' : 'light';
    // const backgroundColor = theme === 'dark' ? 'rgba(17, 17, 17, 1)' : 'rgba(255, 255, 255, 1)';
    useEffect(
        () => {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
        {
          "symbols": [
            ${symbols}
          ],
          "chartOnly": false,
          "width": "100%",
          "height": "100%",
          "locale": "pl",
          "colorTheme": "light",
          "autosize": true,
          "showVolume": false,
          "showMA": false,
          "hideDateRanges": false,
          "hideMarketStatus": false,
          "hideSymbolLogo": false,
          "scalePosition": "right",
          "scaleMode": "Normal",
          "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          "fontSize": "10",
          "noTimeScale": false,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "area",
          "maLineColor": "#2962FF",
          "maLineWidth": 1,
          "maLength": 9,
          "backgroundColor": "rgba(235, 232, 232, 1)",
          "lineWidth": 2,
          "lineType": 0,
          "dateRanges": [
            "1d|1",
            "1m|30",
            "3m|60",
            "12m|1D",
            "60m|1W",
            "all|1M"
          ],
          "lineColorGrowing": "rgba(30, 216, 138, 1)",
          "lineColorFalling": "rgba(217, 0, 66, 1)"
        }`;
            container.current.appendChild(script);
        },

    );

    return (
        <div className="tradingview-widget-container" ref={container}>
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright">
                <a href="https://pl.tradingview.com/" rel="noopener noreferrer nofollow" target="_blank"><span className="blue-text">Monitoruj wszystkie rynki na TradingView</span>
                </a>
            </div>
        </div>
    );
}

export default memo(LineChartWidget);
