import React from 'react';
import error404Icon from '../../img/icons/error404Icon.png';
import '../../sass/notFound.css';
import {useNavigate} from "react-router-dom";

const NotFound = () => {

    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        navigate('/');
    }
    return (
        <section className="notFound-wrapper">
            <div className="notFound-img-wrapper">
                <img className="notFound-img" src={error404Icon} alt="ikona przedstawiająca napis 404."/>
            </div>
            <p className="notFound-text">Nie udało nam się znaleźć</p>
            <p className="notFound-text">strony, której szukasz.</p>
            <div className="notFound-button-wrapper">
                <button onClick={handleBackButtonClick}>Wróć na stronę główną</button>
            </div>
        </section>
    );
};

export default NotFound;
